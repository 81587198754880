@use 'scss/animation';

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 135px;
    height: 75px;
  }
}

.loaderText {
  @include animation.pulse;
  display: block;
}
