@use 'scss/text';
@use 'scss/border';
@use 'scss/element';
@use 'scss/shadow';
@use 'scss/animation';

.eventList {
  @include element.exceed-global-padding;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-white);
}

.seeMoreButton {
  @include text.medium-grey(18px);
  text-transform: uppercase;
  margin: 12px auto 0;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    height: 16px;
    width: 16px;
    fill: var(--theme-grey-dark);
  }
}

.eventCard {
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-content: center;
  gap: 8px;
  padding: 8px 16px;

  &:nth-child(odd) {
    background-color: color-mix(in srgb, var(--theme-secondary) 5%, transparent);
  }

  .adornment {
    background-color: var(--theme-primary);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    gap: 4px;

    p {
      @include text.medium-white(13px);
      text-align: center;
      color: white;

      &.number {
        font-size: 30px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;

    .title {
      @include text.medium-primary(20px);
    }

    .subtext {
      @include text.normal-grey(15px);
    }

    ul.detailsList {
      @include animation.fadeIn(0.15s);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 4px;

      li {
        display: flex;
        align-items: center;
        column-gap: 8px;

        p {
          @include text.normal-grey(15px);
        }
        svg {
          height: 20px;
          width: 20px;
          fill: var(--theme-grey-dark);
        }
      }
    }
  }

  .buttonWrapper {
    height: 64px;
    padding: 4px;
    display: grid;
    place-content: center;

    .expandButton {
      svg {
        height: 16px;
        width: 16px;
        fill: var(--theme-grey-dark);
        transition: 0.15s;
        transform-origin: center 6px;
      }
      &.expanded {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
