// SCSS Variables
$toggleWidth: 60px;
$toggleHeight: 30px;
$toggleBorderRadius: 15px;
$toggleBackgroundColor: #818181;
$toggleOnBackgroundColor: var(--theme-secondary);
$toggleSwitchSize: 28px;
$toggleSwitchBackgroundColor: white;
$toggleSwitchTop: 1px;
$toggleSwitchLeft: 1px;

// Toggle Styles
.toggleWrapper {
  position: relative;
  display: inline-block;

  .toggleInput {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .toggle {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    width: $toggleWidth;
    height: $toggleHeight;
    border-radius: $toggleBorderRadius;
    background-color: $toggleBackgroundColor;
    position: relative;

    &.on {
      background-color: $toggleOnBackgroundColor;

      .toggleSwitch {
        left: calc(100% - #{$toggleSwitchSize} - 1px);
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    .toggleSwitch {
      position: absolute;
      top: $toggleSwitchTop;
      left: $toggleSwitchLeft;
      width: $toggleSwitchSize;
      height: $toggleSwitchSize;
      background-color: $toggleSwitchBackgroundColor;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: left 0.3s;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
