.admin {
    padding: 20px;

    h1 {
        margin-bottom: 20px;
    }

    h2 {
        margin-bottom: 10px;
    }

    h3 {
        margin-top: 20px;
    }

    .description {
        font-size: 12px;
        margin-bottom: 10px;
    }

    table {
        td {
            padding: 3px;
            vertical-align: top;
        }

        tr > td:first-child {
            font-weight: 500;
            padding-right: 10px;
        }
    }

    p {
        margin-bottom: 10px;
    }

    code {
        font-family: monospace, sans-serif;
    }

    .error {
        color: red;
    }

    .submit-btn {
        margin: 15px 0;
    }
}