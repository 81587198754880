// fadeDrop
@keyframes fadeDrop {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@mixin fadeDrop($duration: 0.2s) {
  animation: fadeDrop $duration;
}

// fadeIn
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@mixin fadeIn($duration: 0.1s) {
  animation: fadeIn $duration;
}

// shimmer
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@mixin shimmer($duration: 5s) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background-color: var(--theme-white);
  background: linear-gradient(-70deg, #eee 10%, #ccc 25%, #eee 40%);
  background-size: cover;
}

// pulse
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin pulse($duration: 2s) {
  animation: pulse $duration infinite ease-in-out;
}
