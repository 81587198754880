.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 24px;
	border-radius: 12px 12px 0 0;

	.heading {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;

		.closeButton {
			align-self: flex-end;
		}

		h1 {
			color: #000;
			font-family: 'Helvetica', sans-serif;
			font-weight: 500;
			font-size: 26px;
			margin: 12px 0;
			text-transform: capitalize;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		h2 {
			color: #707070;
			font-family: 'Poppins', sans-serif;
			font-weight: 300;
			font-size: 16px;
			margin-bottom: 12px;
		}
	}
}
