.container {
  border: 1px solid var(--toca-theme-grey-faded2);
  border-radius: 5.5px;
  padding: 12px 16px;
  margin-bottom: 20px;

  &.selected {
    border: 2px solid var(--toca-website-primary-color);
    padding: 11px 15px;
  }
}

.topSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #969696;

  .date {
    flex: 1;
    text-align: right;
    color: var(--toca-website-primary-color);
  }

  &.small {
    align-items: flex-start;

    .dateSmall {
      font-size: 22px;
      font-weight: 500;
      color: #000;
    }
  }
}

.main {
  color: #000;
  font-family: 'Helvetica';
  font-weight: 300;
  display: flex;
  flex-direction: column;

  .date {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .time {
    font-size: 16px;
    margin-bottom: 4px;

    .zone {
      font-size: 13px;
    }
  }

  .coach {
    font-size: 14px;
    color: var(--toca-theme-grey2);
    //TODO Avenir-Medium
    margin-bottom: 4px;
  }
}
