@use 'scss/text';
@use 'scss/border';

.container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 4px 20px;
  text-align: center;
  // transition: 0.15s;

  p {
    @include text.ellipsis;
  }

  &.light {
    @include border.thin-grey-dark;
    @include border.radius-small;
    @include text.heading-grey(14px);
    background-color: var(--theme-white);

    // &:hover {
    //   @include border.thin-primary;
    //   @include border.radius-small;
    //   @include text.heading-primary(14px);
    // }
  }
  &.primary {
    @include border.thin-primary;
    @include border.radius-small;
    @include text.heading-white(14px);
    background-color: var(--theme-primary);
  }

  &.alert {
    @include border.thin-alert;
    @include border.radius-small;
    @include text.heading-alert(14px);
    background-color: var(--theme-white);
  }

  &.danger {
    @include border.thin-alert;
    @include border.radius-small;
    @include text.heading-white(14px);
    background-color: var(--theme-alert);
  }
}
