@use 'scss/text';

.sessionSection {
  margin-bottom: 36px;
}

.placeholderText {
  @include text.normal-grey(13px);
  text-align: center;
}

.sessionCardWrapper {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .cancelButton {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
      fill: var(--theme-grey);
      transition: 0.2s;
    }

    &:hover {
      svg {
        fill: var(--theme-grey-dark);
      }
    }
  }
}

.cancelSessionContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .alertText {
    @include text.medium-grey(12px);
    color: var(--theme-alert); // TODO STYLES confirm color and create fixed text mixin
    text-align: center;

    span {
      font-size: 13px;
    }
  }

  .contactText {
    @include text.semibold-primary(15px);

    span {
      @include text.medium-primary(15px);
      text-transform: uppercase;
      margin-right: 0.5em;
    }
  }
}
