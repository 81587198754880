@use 'scss/text';
@use 'scss/border';
@use 'scss/shadow';

.container {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  @include border.radius-standard;
  @include shadow.slight-black;

  .buttonText {
    @include text.medium-white(12px);
    letter-spacing: 2.4px;
  }
}
