@use 'scss/text';
@use 'scss/border';
@use 'scss/shadow';

.container {
  @include shadow.dispersed-black-bottom;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.carouselSlide {
  width: 80px;
}

.carouselItem {
  @include border.thin-grey-light;
  @include border.radius-small;
  cursor: pointer;
  width: 80px;
  height: 80px;
  max-width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.selected {
    @include border.thin-blue;
    @include border.radius-small;
    background-color: var(--theme-blue);

    p,
    span {
      color: var(--toca-theme-white) !important;
    }
  }

  &.empty {
    opacity: 0.4 !important;
  }
}

.day {
  @include text.medium-black(20px);
}

.date {
  @include text.normal-grey(13px);
  text-transform: uppercase;

  span {
    @include text.medium-grey(16px);
  }
}
