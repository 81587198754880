@use 'scss/element';
@use 'scss/text';
@use 'scss/border';

.container {
  @include element.exceed-global-padding;
  padding: 12px 20px;
  max-width: 100vw;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  .avatar {
    height: 64px;
    width: 64px;
    min-width: 64px;
    border: 2px solid var(--theme-grey-xlight);
    border-radius: 50%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  .profileInfo {
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;

    .name {
      @include text.semibold-white(22px);
      line-height: 22px;
    }

    .contact {
      display: flex;
      gap: 8px;

      .email {
        @include text.medium-primary(15px);
        // flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        background-color: var(--theme-white);
        padding: 4px 8px;
        border-radius: 4px;
        box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.1);

        span {
          font-weight: 700;
        }
      }

      .contactButton {
        display: grid;
        place-content: center;
        background-color: var(--theme-white);
        padding: 4px 8px;
        border-radius: 4px;
        box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.1);

        .icon {
          width: 36px;
          svg {
            height: 24px;
          }
        }
      }
    }
  }

  // .stats {
  //   @include border.thin-transparent;
  //   display: flex;
  //   justify-content: space-evenly;

  //   &.beginner {
  //     background-color: var(--theme-pathway-1);
  //   }
  //   &.intermediate {
  //     background-color: var(--theme-pathway-2);
  //   }
  //   &.advanced {
  //     background-color: var(--theme-pathway-3);
  //     .infoHeading,
  //     .info {
  //       color: var(--theme-white) !important;
  //     }
  //   }

  //   .column {
  //     display: flex;
  //     flex-direction: column;
  //     padding: 4px;

  //     p {
  //       line-height: 16px;
  //     }

  //     .infoHeading {
  //       @include text.normal-primary(12px);
  //       text-transform: uppercase;
  //     }

  //     .info {
  //       @include text.bold-primary(12px);
  //       text-transform: capitalize;
  //       line-height: 16px;
  //     }
  //   }
  // }
}
