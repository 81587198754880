@use 'scss/text';
@use 'scss/border';
@use 'scss/shadow';
@use 'scss/animation';

.flexContainer {
  @include animation.fadeIn(0.15s);
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  section {
    flex: 1;
  }
}

.descriptionText {
  @include text.normal-grey(16px);
  line-height: 24px;
}

.educationLink {
  @include text.medium-primary(18px);
  text-align: center;
  text-decoration: underline;
  // color: var(--theme-secondary);
  color: var(--theme-secondary-alt);
}

.objectivesCard {
  @include border.thin-grey-light;
  @include shadow.dispersed-black;
  overflow: hidden;

  .imageWrapper {
    height: 160px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cardContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    h4 {
      text-align: center;
      @include text.semibold-primary(18px);
    }

    ul {
      list-style: outside;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        @include text.normal-primary(15px);
      }
    }
  }
}

.metricsList {
  display: flex;
  // flex-wrap: wrap;
  gap: 16px;
}

.statCard {
  @include border.thick-grey-light;
  @include shadow.dispersed-black;
  display: grid;
  align-items: center;
  background-color: (--theme-white);
  padding: 12px;

  // .statValueWrapper {
  //   aspect-ratio: 1 / 1;
  //   display: flex;
  //   align-items: stretch;
  //   justify-content: stretch;

  //   .statValueBackground {
  //     aspect-ratio: 1 / 1;
  //     height: 85%;
  //     max-height: 102px;
  //     min-height: 72px;
  //     margin: auto;
  //     border-radius: 50%;
  //     background-color: var(--theme-primary);
  //     display: grid;
  //     place-content: center;

  //     .statValue {
  //       @include text.medium-white(26px);
  //     }
  //   }
  // }

  .text {
    h4 {
      @include text.semibold-primary(18px);
      line-height: 24px;
      margin-bottom: 4px;
    }

    p {
      @include text.normal-grey(15px);
      line-height: 20px;
    }
  }
}
