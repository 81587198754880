@use 'scss/text';
@use 'scss/element';

// TODO lots of potential reuse with ClinicsList if design isn't altered
.sessionList {
  @include element.exceed-global-padding;
  display: flex;
  flex-direction: column;

  li {
    &:nth-child(even) {
      background-color: color-mix(in srgb, var(--theme-secondary) 5%, transparent);
    }
    &:last-child {
      border-bottom: 1px solid color-mix(in srgb, var(--theme-secondary) 15%, transparent);
    }
  }

  .sessionCard {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 8px 16px;

    .adornment {
      background-color: var(--theme-primary);
      width: 64px;
      height: 64px;
      border-radius: 50%;
      display: grid;
      place-content: center;
      gap: 4px;

      p {
        @include text.medium-white(13px);
        text-align: center;
        color: white;

        &.number {
          font-size: 30px;
        }
      }
    }

    .sessionDetails {
      grid-column: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .date {
        @include text.medium-primary(20px);
      }

      .coach {
        @include text.normal-grey(15px);
      }

      .error {
        @include text.semibold-primary(20px);

        color: var(--theme-alert);
      }
    }
  }
}

.baselineCard {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 8px 16px;
  border-top: 1px solid color-mix(in srgb, var(--theme-secondary) 15%, transparent);
  border-bottom: 1px solid color-mix(in srgb, var(--theme-secondary) 15%, transparent);

  .sessionDetails {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .baselineText {
      @include text.medium-primary(20px);
      color: var(--theme-secondary);
    }

    .baselineDate {
      @include text.normal-grey(15px);
    }
  }

  .adornment {
    background-color: var(--theme-secondary);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: grid;
    place-content: center;

    svg {
      height: 40px;
      width: 40px;
      fill: var(--theme-white);
    }

    &.upcoming {
      background-color: var(--theme-white);
      border: 2px solid var(--theme-secondary);

      svg {
        height: 40px;
        width: 40px;
        fill: var(--theme-secondary);
      }
    }
  }
}

.baselineCountdown {
  padding: 12px 20px;
  border-bottom: 1px solid color-mix(in srgb, var(--theme-secondary) 15%, transparent);
  // background-color: white;

  p {
    @include text.semibold-primary(20px);
    color: var(--theme-secondary);
    text-align: center;
  }

  // p {
  //   padding: 4px 8px;
  //   @include text.medium-white(20px);
  //   background-color: var(--theme-secondary);
  //   text-align: center;
  // }
}
