@use 'scss/text';

.formSelect {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  margin: 0;
  border-radius: 12px;
  background: url('../../../assets/img/icons/new-chevron-down-black.svg') var(--toca-theme-offwhite) no-repeat bottom
    50% right 13px;
  @include text.font-poppins-light(16px);
  color: var(--toca-theme-black-faded2);
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid transparent;

  &:focus {
    border: 1px solid var(--toca-website-error-color);
  }

  &:disabled {
    border: 1px solid var(--toca-theme-black-faded);
    background-color: var(--toca-theme-white);
    background: var(--toca-theme-offwhite) no-repeat bottom 50% right 13px;
    opacity: 1;
    cursor: default;
  }

  &.errorState {
    border: 1px solid var(--toca-website-error-color);
    color: var(--toca-website-error-color);
  }
}
