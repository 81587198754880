@use 'scss/text';
@use 'scss/border';
@use 'scss/animation';

.container {
  @include border.thick-grey;
  padding: 20px 24px;
  position: relative;

  &.highlight {
    @include border.thick-blue;
    box-shadow: inset 1px var(--theme-blue); // TODO STYLES confirm
  }

  &.disabled {
    background-color: var(--theme-grey-xlight); // add background color
    .content {
      .topRow {
        h5 {
          @include text.bold-grey(16px); // Session title no longer Blue
        }
      }
      .info {
        p {
          span {
            @include text.normal-grey(16px); // "Coach" no longer bold
          }
        }
      }
    }
  }

  &.loading {
    height: 108px; // TODO STYLES ?
    padding: 0;

    .loadingAnimation {
      @include animation.shimmer;
      height: 100%;
      display: grid;
      place-content: center;

      p {
        @include text.heading-primary(16px);
        text-align: center;
        text-transform: capitalize; // TODO STYLES standardise
        line-height: 20px;
      }
    }
  }

  .eyebrow {
    pointer-events: none;
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    @include border.radius-standard;

    p {
      @include border.radius-small;
      @include text.heading-white(14px);
      padding: 0 12px;
      display: inline-block;
      position: relative;
      top: 2px;
      left: 24px;
      transform: translateY(-50%);
    }

    &.confirmed {
      box-shadow: inset 0 0 0 2px var(--theme-success);
      p {
        background-color: var(--theme-success);
      }
    }

    &.error {
      box-shadow: inset 0 0 0 2px var(--theme-alert);
      p {
        background-color: var(--theme-alert);
      }
    }

    &.unavailableTime {
      box-shadow: inset 0 0 0 2px var(--theme-alert);
      p {
        background-color: var(--theme-alert);
      }
    }

    &.editing {
      box-shadow: inset 0 0 0 2px var(--theme-blue);
      p {
        background-color: var(--theme-blue);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 12px;

    .topRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        @include text.bold-blue(16px);
        @include text.ellipsis;
        text-transform: uppercase;
        line-height: 20px;
      }

      .editIcon,
      .confirmedIcon {
        height: 20px;
        width: 20px;
        margin-right: -12px;
        margin-top: -4px;
        display: grid;
        place-content: center;

        svg {
          height: 24px;
          width: 24px;
        }
      }
      .editIcon svg {
        fill: var(--theme-blue);
        cursor: pointer;
      }
      .confirmedIcon svg {
        fill: var(--theme-success);
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .dateAndTime {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        &.flexRow {
          flex-direction: row;
          column-gap: 8px;
        }
      }

      p {
        @include text.normal-grey(16px);
        line-height: 20px;

        span {
          @include text.bold-grey(16px);
        }
      }

      span.zone {
        @include text.normal-grey(13px);
      }
    }
  }
}

.buttonTrayContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 4px;

  .dangerText {
    @include text.medium-alert(14px);
  }
}
