@use 'scss/element';

.container {
  @include element.exceed-global-padding-classic;
  position: relative;
  height: 100vh;
  background-color: #fff;
  color: #000;
  overflow: hidden;
}
