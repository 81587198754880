@use 'scss/text';

.container {
  text-align: center;

  h2 {
    @include text.bold-black(12px);
    text-decoration: underline;
    text-transform: uppercase;
    margin: 8px 0;
  }

  p {
    @include text.semibold-grey(11px);
  }
}
