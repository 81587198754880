// all borders include standard corner radius.
%standard-border-radius {
  border-radius: 12px;
}
%small-border-radius {
  border-radius: 6px;
}

// use the following override for small radius
@mixin radius-small {
  @extend %small-border-radius;
}
//
@mixin radius-standard {
  @extend %standard-border-radius;
}

// thin 1px
@mixin thin-grey {
  @extend %standard-border-radius;
  border: 1px solid var(--theme-grey-light-alt);
}
@mixin thin-grey-light {
  @extend %standard-border-radius;
  border: 1px solid var(--theme-grey-xlight-alt);
}
@mixin thin-grey-dark {
  @extend %standard-border-radius;
  border: 1px solid var(--theme-grey-dark);
}
@mixin thin-primary {
  @extend %standard-border-radius;
  border: 1px solid var(--theme-primary);
}
@mixin thin-blue {
  @extend %standard-border-radius;
  border: 1px solid var(--theme-blue);
}
@mixin thin-alert {
  @extend %standard-border-radius;
  border: 1px solid var(--theme-alert);
}
@mixin thin-transparent {
  @extend %standard-border-radius;
  border: 1px solid transparent;
}

// thick 2px
@mixin thick-grey {
  @extend %standard-border-radius;
  border: 2px solid var(--theme-grey-light-alt);
}
@mixin thick-grey-light {
  @extend %standard-border-radius;
  border: 2px solid var(--theme-grey-xlight-alt);
}
@mixin thick-grey-dark {
  @extend %standard-border-radius;
  border: 2px solid var(--theme-grey-dark);
}
@mixin thick-primary {
  @extend %standard-border-radius;
  border: 2px solid var(--theme-primary);
}
@mixin thick-blue {
  @extend %standard-border-radius;
  border: 2px solid var(--theme-blue);
}
@mixin thick-alert {
  @extend %standard-border-radius;
  border: 2px solid var(--theme-alert);
}
@mixin thick-transparent {
  @extend %standard-border-radius;
  border: 2px solid transparent;
}

// unique
@mixin thin-grey-bottom {
  border-bottom: 1px solid var(--theme-grey-light-alt);
}
@mixin thin-grey-bottom-dark {
  border-bottom: 1px solid var(--theme-grey-dark);
}

// tables
@mixin table-grey-dark {
  border: 2px solid var(--theme-grey-dark);
}
