@use 'scss/text';

.container {
  th {
    @include text.bold-black(10px);
    text-transform: uppercase;
  }
  td {
    @include text.semibold-grey(11px);
  }

  tr.blankRow {
    height: 8px;
    border: none;
  }

  .levelHeading {
    padding: 8px;
  }

  .levelTotals,
  .levelHeading {
    @include text.subheading-primary(14px);
    letter-spacing: 0.5px; // TODO confirm new text preset requirements

    &:nth-of-type(odd) {
      background-color: var(--theme-grey-light-alt);
    }
  }
}
