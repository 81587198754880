@use 'scss/text';

.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formUpper {
  flex: 0;
}

.formLower {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.invisible {
  display: none;
}

.err-message {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--toca-website-error-color);
}

.accountHolderText {
  @include text.font-poppins(16px);
  text-transform: capitalize;
  text-align: center;
}

.checkboxWrapper {
  margin-bottom: 16px;
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.checkboxText {
  @include text.font-poppins-light(16px);
  color: var(--toca-theme-black-faded2);
  text-align: left;
}
