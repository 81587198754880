.container {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullHeight {
    height: 90vh;
    max-height: 100%;
  }
}
