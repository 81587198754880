@use 'scss/text';
@import './LeaderboardDetailsMixins.module.scss';

.container {
  min-height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
  .infoFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;

    @media (min-width: 768px) {
      max-width: 50%;
    }
    @media (min-width: 1080px) {
      max-width: 600px;
    }
  }
}

.subHeading {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-black);
  margin-bottom: 4px;
}

.fkoBanner {
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f7921d;
  box-shadow: 0 0 6px -1px #fff;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    box-shadow: 0 0 8px 0 #fff;
  }

  span {
    @include text-poppin-white-14;
    line-height: 32px;
    text-decoration: underline;
    @media (max-width: 380px) {
      font-size: 12px;
    }
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 40px;
    }
  }
}

.leaderboardInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;

  @media (min-width: 768px) {
    flex: 0;
    padding: 24px 0px;
    margin-right: 4px;
  }

  @media (min-width: 1024px) {
    flex: 0;
    padding: 24px 16px;
    margin-right: 12px;
  }

  // @media (min-width: 1800px) {
  //   margin-right: 124px;
  // }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;

  @media (min-width: 768px) {
    flex: 1.5;
    padding: 12px;
  }

  .sectionHeadingWrapper {
    padding: 0 12px;
  }
}

.notFound {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}

.centeredContainer {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
} //TODO
