@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Poppins', system-ui, sans-serif;
  }
}
@layer utilities {
  .debug {
    @apply border-[2px] border-red-500;
  }

  .exceed-global-padding {
    margin-left: -20px;
    margin-right: -20px;

    @media (min-width: 768px) {
      margin-left: -32px;
      margin-right: -32px;
    }
    @media (min-width: 1024px) {
      margin-left: -48px;
      margin-right: -48px;
    }
  }

  .exceed-classic-padding {
    margin-left: -32px;
    margin-right: -32px;
    width: calc(100% + 64px);
  }

  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}

@import 'swiper/swiper-bundle.css';

:root {
  // TODO delete
  --primary: #f7931d;
  --secondary: #f35d1b;
  --tertiary: #f2281c;
  --toca-theme-primary: #f7931d;
  --toca-theme-secondary: #f35d1b;
  --toca-theme-tertiary: #f2281c;

  // FKO colors
  body {
    --toca-website-primary-color: #051274;
    --toca-website-highlight-color: #5517e0;
    --toca-website-error-color: #e64040;
    --toca-website-checkbox-color: #e64040;
    --toca-website-primary-btn-color: #051274;
    --toca-website-primary-btn-text-color: #fcfcff;
    --toca-website-secondary-btn-color: #fff;
    --toca-website-secondary-btn-text-color: #081c3a;
    --toca-website-secondary-btn-border-color: rgba(192, 192, 211, 0.2);
    --toca-website-fko-carousel: #000;
    --toca-website-fko-carousel-secondary: #00457d;
    --toca-website-fko-carousel-grey: #999;
  }

  body[data-theme='DBAT'] {
    --toca-website-primary-color: #00457d;
    --toca-website-highlight-color: #3cb64a;
    --toca-website-error-color: #e64040;
    --toca-website-checkbox-color: #00457d;
    --toca-website-primary-btn-color: #3cb64a;
    --toca-website-primary-btn-text-color: #fcfcff;
    --toca-website-secondary-btn-color: #fff;
    --toca-website-secondary-btn-text-color: #00457d;
    --toca-website-secondary-btn-border-color: rgba(192, 192, 211, 0.2);
    --toca-website-fko-carousel: #00457d;
    --toca-website-fko-carousel-secondary: #5517e0;
    --toca-website-fko-carousel-grey: #999;
  }

  // TODO we should probably have these styles at the component level
  body[data-theme='PLAYER_ASSESSMENT'] {
    &.fko-registration-page {
      .page-container {
        background-image: linear-gradient(to bottom, #0d1367 0%, #522bde 250px, #8f72f4 500px, #fff 500px);

        .player-assessment-heading-section {
          color: #fff;
        }

        [data-form-section='center-name'] {
          display: none;
        }

        .form-fields-wrapper {
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 12px;
          padding: 20px 20px 5px;

          input[type='text'],
          input[type='email'],
          input[type='tel'] {
            background-color: #f4f4f6;
          }
        }
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  // additional color vars
  --toca-theme-black: #000000;
  --toca-theme-white: #ffffff;
  --toca-theme-offwhite: #f7f7f7;
  --toca-theme-grey1: #d0d0d0;
  --toca-theme-grey2: #8a8a8a;
  --toca-theme-grey4: #474d5d; // TODO single use
  --toca-theme-grey5: #575757; // TODO single use
  --toca-theme-grey6: #979797;
  --toca-theme-grey7: #424242;
  --toca-theme-grey9: #c7c7c7; // tailwind "grey-dark"

  --toca-theme-grey-faded: #c7c7c766;
  --toca-theme-grey-faded2: rgba(192, 192, 211, 0.4);
  --toca-theme-black-faded: rgba(0, 0, 0, 0.4);
  --toca-theme-black-faded2: rgba(0, 0, 0, 0.6);

  --toca-theme-blue: #2244aa;
  --toca-theme-link-blue: #3f99f7;
  --toca-theme-deep-blue: #0e152b; // TODO exists for two SVGs
  --toca-theme-red-alternate: #c60303;

  // RETHEME
  --theme-primary: #0d1367;
  --theme-secondary: #5517e0;
  --theme-tertiary: #904efc;
  --theme-white: #ffffff;
  --theme-black: #000000;
  --theme-grey-xlight: #f7f7f7;
  --theme-grey-xlight-alt: #e4e4e4;
  // --theme-grey-alt: #dedfe1; // heavy borders only?
  --theme-grey-light-alt: #d8d8d8;
  --theme-grey-light: #d2d2d2;
  --theme-grey: #c7c7c7;
  --theme-grey-dark: #5c636b; // grey text
  // --theme-grey-dark-alt: #5f5f69;
  --theme-blue: #2731ba;
  --theme-blue-light: #5b62c1;
  --theme-blue-dark: #1f263b;
  --theme-bluegrey: #838899;
  //
  --theme-pathway-1: #ebfa44;
  --theme-pathway-2: #00d6f4;
  --theme-pathway-3: #5d35ed;
  --theme-alert: #e64040;
  --theme-success: #08c132;
  // skill levels?
  --theme-level-beginner: rgb(239, 255, 0);
  --theme-level-intermediate: rgb(66, 218, 246);
  --theme-level-advanced: rgb(99, 45, 242);
  --theme-level-pro: rgb(9, 6, 116);
  // here comes the new colors, same as the old colors.  why tho.
  --theme-primary-alt: #051274;
  --theme-secondary-alt: #643df2;

  //
  --theme-text-primary: var(--theme-primary);
  --theme-text-grey: var(--theme-grey-dark);
  --theme-text-lightgrey: var(--theme-grey-light);
  --theme-text-white: var(--theme-white);
  --theme-text-black: var(--theme-blue-dark);

  //
  --theme-border-radius-large: 10px;
  --sitewide-max-width: 1400px;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-BlackItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Black.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Bold.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-BoldItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-MediumItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-SemiBold.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Regular.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Italic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Thin.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-ExtraLight.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Light.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-ThinItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-Medium.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('./assets/font/Poppins/Poppins-LightItalic.woff2') format('woff2'),
    url('./assets/font/Poppins/Poppins-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// Redesign Fonts
@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/font/RobotoCondensed/RobotoCondensed-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/font/RobotoCondensed/RobotoCondensed-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Teko';
  src: url('./assets/font/Teko/Teko-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//
html {
  scroll-behavior: smooth;
  font-size: 62.5%; //1 rem = 10px;
}

body {
  width: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

button {
  // border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
}

a {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

ul {
  list-style: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    transition: all 0.3s;
    border-radius: 12px;
    background-color: var(--toca-theme-offwhite);
    padding: 13px 16px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba($color: #000000, $alpha: 0.4);

    &:focus {
      border: none;
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgba($color: #000000, $alpha: 0.4);
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #121729;
    margin-bottom: 8px;

    a {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba($color: #13255c, $alpha: 1);
    }
  }

  &.error {
    input {
      border: 1px solid var(--toca-website-error-color);
      color: var(--toca-website-error-color);
    }
  }
}

.submit-btn {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #474d5d;
  background-color: #474d5d;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  transition: all 1s;
  display: flex;
  align-items: center;
  justify-content: center;

  &.red {
    background-color: var(--toca-website-error-color);
    border-color: var(--toca-website-error-color);
    transition: all 1s;
  }
}

.link-btn {
  height: 40px;
  border-radius: 12px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #474d5d;
  background-color: #474d5d;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  transition: all 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  &.red {
    background-color: var(--toca-website-error-color);
    border-color: var(--toca-website-error-color);
    transition: all 1s;
  }
}

.err-message {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--toca-website-error-color);
}

.simple-loader {
  padding: 24px 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.stats-linear-gradient {
  background: linear-gradient(180deg, #0d1367 0%, #522bde 50%, #8f72f4 100%);
}

.crop-container .crop-area {
  transform: translate(-50%, -50%) rotate(45deg) scale(0.705);
}
