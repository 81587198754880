@use 'scss/text';
@use 'scss/border';

.container {
  display: flex;
}

.inputContainer {
  flex: 1;
}

.resultsContainer {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  max-width: 0;
  opacity: 0;
  transition:
    opacity 0.5s ease 1.5s,
    max-width 0.25s ease 1.75s;
  overflow: hidden;

  &.isOpen {
    opacity: 1;
    transition:
      opacity 0.4s ease,
      max-width 0.25s ease;
    max-width: 100%;
  }

  .resultsText {
    display: flex;
    align-items: center;
    flex: 2;
    overflow: hidden;
    p {
      @include text.normal-black(12px);
      width: 100%;
      white-space: nowrap;
      text-align: center;
    }
  }
  .buttonContainer {
    display: flex;
    flex: 3;

    & :not(:last-child) {
      margin-right: 8px;
    }

    .searchButton {
      border: 1px solid var(--theme-black);
      border-radius: 4px;
      min-width: 36px;
      display: grid;
      place-content: center;

      svg {
        fill: var(--theme-black);
        height: 20px;
        width: 20px;

        &.reverse {
          transform: rotate(180deg);
        }
      }
    }
  }
}
