@use 'scss/text';
@use 'scss/border';

.accordionContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.cardContainer {
  @include border.thin-grey;
  width: 100%;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  background-color: white;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      @include text.normal-primary(16px);
      display: flex;
      text-align: left;
    }

    .icon {
      display: grid;
      place-content: center;
      margin-left: 16px;

      svg {
        width: 12px;
        height: 12px;
        transition: transform 0.2s 0.15s ease-in-out; // delay matches nicely with longer height animation
      }
    }
  }
  &.active .heading .icon svg {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }

  .contentContainer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out;

    .content {
      margin-top: 24px;
    }
  }
  &.active .contentContainer {
    max-height: 600px; // TODO needed?
    overflow: auto;
  }
}
