@import "../../scss/common";

#splash .splash-container {
  background-color: var(--theme-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--theme-primary);
  font-size: 29px;
  font-style: normal;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 9999;

  .toca-splash-logo {
    z-index: 9999;
    width: 228px;
    height: 68px;
    object-fit: contain;
    margin-bottom: 3.2rem;
  }

  .toca-spinning-ball-container {
    width: 100px;
    height: 72px;
    z-index: 10;
  }
}
