.container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 5.5px;
  border: solid 1px #e6e6ed;
  transition: all 0.2s;
  margin-bottom: 20px;

  &.hasError {
    border: 2px solid var(--toca-website-error-color);
  }

  .flexSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;
  }
  .fixedSection {
    flex-grow: 0;

    .checkIcon {
      svg {
        height: 25px;
        width: 25px;
        fill: var(--toca-website-primary-color);
      }
    }
  }
}

.playerName {
  color: #000;
  font-size: 18px;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 30px;

  max-width: 90%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.grey {
    color: #848484;
  }
}

.scheduleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: #000;
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  text-transform: uppercase;
}

.scheduledText {
  color: var(--toca-website-primary-color);
  font-size: 12px;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 30px;
}

.errorText {
  color: var(--toca-website-error-color);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;

  :global {
    svg {
      width: 32px;
      margin: 5px 10px 0 0;

      .exclamation-mark-cls-1 {
        fill: var(--toca-website-error-color);
      }
    }
  }
}

.editButton {
  cursor: pointer;
  padding: 4px 12px;
  min-width: 0;

  border-radius: 2.5px;
  border: 1px solid var(--toca-theme-grey-faded2);
  display: flex;
  align-items: center;

  p {
    color: #7d7d7d;
    font-family: 'Helvetica', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .icon {
    margin-right: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.scheduleButton {
  cursor: pointer;
  padding: 4px 16px;
  margin-top: 8px;
  border-radius: 2.5px;
  background-color: var(--toca-website-primary-color);
  border: 1px solid var(--toca-website-primary-color);
  color: var(--toca-website-primary-btn-text-color);
  display: flex;
  align-items: center;

  p {
    // color: #fff;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .icon {
    margin-left: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.ineligibleButton {
  cursor: pointer;
  padding: 4px;
  border-radius: 2.5px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;

  &.withBookings {
    color: var(--toca-website-primary-color);
    text-transform: uppercase;

    &:hover {
      border: 1px solid var(--toca-website-primary-color);
      transition: 0.15s;
    }
    svg {
      margin-left: 8px;
      width: 20px;
      height: 20px;
      display: block;
      fill: var(--toca-website-primary-color);
    }
  }

  p {
    color: #848484;
    font-family: 'Helvetica', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }

  .icon {
    margin-left: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
