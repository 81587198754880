.radioInput {
  height: 0px;
  width: 0px;
  opacity: 0;
  &:checked {
    + .radioDisplay {
      background-color: var(--theme-primary);
      border: solid 2px var(--theme-primary);
    }
  }
}

.radioDisplay {
  display: inline-block;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  border: solid 2px var(--theme-primary);
  transition: all 0.15s;
  background: url('../../assets/img/icons/new-checked.svg') var(--theme-white) center no-repeat;
  border-radius: 50%;
}

.radioLabel {
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: var(--theme-black);

  a {
    // TODO STYLES font/style mixin
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--theme-blue);
  }
}

// alt styles
.radioInputCheckbox {
  height: 0px;
  width: 0px;
  opacity: 0;
  &:checked {
    + .radioDisplay {
      background-color: var(--theme-secondary);
      border: solid 1px var(--theme-secondary);
    }
  }
}

.radioDisplayCheckbox {
  display: inline-block;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border: solid 1px var(--theme-secondary);
  transition: all 0.15s;
  background: url('../../assets/img/icons/new-checked.svg') var(--theme-white) center no-repeat;
  border-radius: 4px;
}
