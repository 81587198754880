@use 'scss/text';
@use 'scss/border';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .inputWrapper {
    @include border.thick-grey-light;
    margin-bottom: 12px;
  }

  .helpButton {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .noResults {
    @include text.medium-grey(16px);
    text-align: center;
  }
}
