@use 'scss/text';
@use 'scss/element';

.flexContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.trainerHeading {
  padding: 4px 8px;
  border: 1px solid var(--theme-primary);
  border-radius: 4px;
  background-color: var(--theme-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  p {
    @include text.semibold-primary(18px);
  }
  span {
    @include text.subheading-grey(15px);
    color: var(--theme-grey);
    &.locationName {
      color: var(--theme-primary);
    }
  }

  .heading {
    @include text.subheading-primary(15px);
  }
}

.searchContainer {
  display: flex;
  flex-direction: column;
}

.errorText {
  font-size: 14px;
  margin: 0 16px;
}
