@use 'scss/text';

.container {
  display: flex;
  margin: 40px 0;

  .backButton {
    display: grid;
    place-content: center;
    padding-right: 8px;
    margin-right: 4px;

    svg {
      height: 16px;
      width: 16px;
      fill: var(--theme-grey);
    }
  }

  .headingText {
    @include text.heading-primary(27px);
    line-height: 32px;
  }
}
