@use 'scss/text';
@use 'scss/element';

$buttonDisabledColor: #818181;

.logoBanner {
  @include element.exceed-global-padding;
  padding: 12px;
  display: grid;
  place-content: center;
  // background-color: var(--theme-white); // TODO fix trainer container then re-enable

  .tocaLogo {
    height: 24px;
  }
}

.errorDisplay {
  @include element.exceed-global-padding;
  background-color: var(--theme-grey-xlight-alt);
  display: grid;
  place-content: center;
  padding: 32px;

  p {
    @include text.normal-black(18px);
    text-align: center;
  }
}

.sectionHeading {
  @include text.semibold-primary(18px);
  margin-bottom: 8px;
}

.buttonTray {
  @include element.exceed-global-padding;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px 20px;
  // border-top: 1px solid var(--theme-grey-light-alt);
}

// .buttonTray {
//   position: fixed;
//   bottom: 70px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 100vw;

//   display: flex;
//   align-items: center;
//   gap: 32px;
//   padding: 16px 20px;
//   border-top: 1px solid var(--theme-grey-light-alt);
//   backdrop-filter: blur(8px);
//   -webkit-backdrop-filter: blur(8px);
// }

.button {
  width: 100%;
  display: grid;
  place-content: center;
  padding: 12px 24px;
  border-radius: 8px;
  // transition: all 0.3s;

  &.primary {
    $color1: var(--theme-primary);
    $color2: var(--theme-white);

    border: 2px solid $color1;
    &.standard {
      @include text.semibold-white(16px);
      color: $color1;
      text-transform: uppercase;
      background-color: $color2;
    }
    &.cta {
      @include text.semibold-white(16px);
      text-transform: uppercase;
      background-color: $color1;
    }
  }

  &.secondary {
    $color1: var(--theme-secondary);
    $color2: var(--theme-white);

    border: 2px solid $color1;
    &.standard {
      @include text.semibold-white(16px);
      color: $color1;
      text-transform: uppercase;
      background-color: $color2;
    }
    &.cta {
      @include text.semibold-white(16px);
      text-transform: uppercase;
      background-color: $color1;
    }
  }

  &.grey {
    $color1: var(--theme-grey-dark);
    $color2: var(--theme-white);

    border: 2px solid $color1;
    &.standard {
      @include text.semibold-white(16px);
      color: $color1;
      text-transform: uppercase;
      background-color: $color2;
    }
    &.cta {
      @include text.semibold-white(16px);
      text-transform: uppercase;
      background-color: $color1;
    }
  }

  &[disabled] {
    background-color: $buttonDisabledColor !important;
    border: 2px solid $buttonDisabledColor;
    cursor: not-allowed;
  }
}
