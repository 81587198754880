@use 'scss/text';
@use 'scss/border';

.container {
  display: flex;
  flex-direction: column;

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    transition: all 0.15s;

    @include border.radius-standard;
    @include text.semibold-white(14px);
    text-transform: uppercase;
    height: 36px;
    padding: 0 36px 0 16px;
    background: url('../../../assets/img/icons/redesign-icons/chevron-down.svg') var(--theme-primary) no-repeat bottom
      50% right 13px;
  }

  &.normal select {
    @include border.radius-small;
    @include text.medium-black(14px);
    border: 1px solid var(--theme-grey-light-alt);
    text-transform: capitalize;
    height: 48px;
    padding: 0 36px 0 12px;
    background: url('../../../assets/img/icons/redesign-icons/chevron-down-grey.svg') var(--theme-white) no-repeat
      bottom 50% right 13px;
  }
}
