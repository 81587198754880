@use 'scss/text';
// TODO STYLES color variables

.container {
  width: 100%;
  flex: 1;
}

.spacer {
  display: block;
  height: 13px;
}

.agreementLabel {
  @include text.font-poppins-light(16px);
  color: var(--toca-theme-black-faded2);
  text-align: left;

  a {
    @include text.font-poppins-light(16px);
    // font-size: 16px !important; // TODO STYLES test does this need !important?
    color: var(--toca-website-primary-color);
  }
}

.userInfoWrapper {
  margin-bottom: 16px;
  & :last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.userInfo {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.recaptchaNotice {
  color: #aaa;
  font-size: 10px;
  margin: 20px 0 10px;

  a {
    color: #999;
    text-decoration: underline;
    margin: 0 2px;
  }
}

:global {
  .grecaptcha-badge {
    visibility: hidden;
  }
}
