.modal-portal {
	.modal-backdrop {
		position: fixed;
		padding-bottom: 10px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(51, 51, 51, 0.9);
		opacity: 0;
		transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
		transition-delay: 200ms;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		z-index: 9;

		&.fullscreen {
			padding-bottom: 0;
		}

		& .modal-content {
			transform: translateY(100px);
			transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
		}

		&.active {
			transition-duration: 250ms;
			transition-delay: 0ms;
			opacity: 1;

			& .modal-content {
				transform: translateY(0);
				opacity: 1;
				transition-delay: 150ms;
				transition-duration: 350ms;
			}
		}
	}

	.modal-content {
		position: relative;
		box-sizing: border-box;
		min-height: 50px;
		min-width: 300px;
		max-height: 80%;
		max-width: 80%;
		background-color: #fff;
		border-radius: 2px;
	}
	.modal-content-fullscreen {
		position: relative;
		box-sizing: border-box;
		//min-height: 100%;
		min-width: 100%;
		max-height: 100%;
		max-width: 100%;
		background-color: #fff;
		border-radius: 12px 12px 0 0;
	}

	.modal-backdrop.player {
		background-color: #000;
		transition-delay: 50ms;
		width: 100%;
		padding-bottom: 0;

		.modal-content {
			width: 100%;
			transform: unset;
			background-color: transparent;
			height: 100%;
			max-height: 100%;
			max-width: 100%;
		}

		&.active {
			transition-duration: 250ms;
			transition-delay: 0ms;
			opacity: 1;

			& .modal-content {
				opacity: 1;
				transition-delay: 50ms;
				transition-duration: 350ms;
			}
		}
	}

	//SELECT MODAL CSSS
	.modal-backdrop.select {
		padding-bottom: 0;
		align-items: flex-start;
		background-color: rgba($color: #000000, $alpha: 0.4);

		& .modal-content {
			width: 80%;
			top: 80px;
			max-width: 550px;
			max-height: 100%;
			padding: 15px 4.2px 285.7px 5px;
			border-radius: 14px;
			box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
			background-color: #1f263b;

			transform: translateY(-100px);
			transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
			padding-top: 14px;
			padding-bottom: 14px;

			.header {
				.custom-heading {
					padding: 0 14px;
					margin-bottom: 16px;
				}
			}
		}

		&.active {
			transition-duration: 250ms;
			transition-delay: 0ms;
			opacity: 1;

			& .modal-content {
				transform: translateY(0);
				opacity: 1;
				transition-delay: 150ms;
				transition-duration: 350ms;
			}
		}
	}
}
