@use 'scss/text';
@use 'scss/border';
@use 'scss/shadow';
@use 'scss/animation';

.flexContainer {
  @include animation.fadeIn(0.15s);

  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  section {
    flex: 1;
  }
}

.descriptionText {
  @include text.normal-grey(16px);
  line-height: 24px;
}

ul.keyObjectives {
  list-style: outside;
  margin: 0 auto;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    line-height: 20px;
    @include text.normal-grey(16px);
  }
}

.metricsList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.statCard {
  @include border.thick-grey-light;
  @include shadow.dispersed-black;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  background-color: (--theme-white);
  padding: 12px;
  width: 100%;

  .statValueWrapper {
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    .statValueBackground {
      aspect-ratio: 1 / 1;
      height: 85%;
      max-height: 102px;
      min-height: 72px;
      margin: auto;
      border-radius: 50%;
      background-color: var(--theme-primary);
      display: grid;
      place-content: center;

      .statValue {
        @include text.medium-white(26px);
      }
    }
  }

  .text {
    h4 {
      @include text.semibold-primary(18px);
      line-height: 24px;
      margin-bottom: 4px;
    }

    p {
      @include text.normal-grey(15px);
      line-height: 20px;
    }
  }
}
