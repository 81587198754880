@use 'scss/text';
@use 'scss/border';

.container {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.subHeading {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-black);
  margin-bottom: 4px;
}

.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    &:not(:last-of-type) {
      margin-right: 32px;
    }
  }

  p.fallbackText {
    @include border.thin-grey;
    @include text.medium-grey(14px);
    padding: 24px;
  }
}

.centeredContainer {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}

.presentationContainer {
  margin-top: 15vh;
  margin-bottom: 20vh;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 24px;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }
}
