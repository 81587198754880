@use 'scss/animation';

.container {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  // max-width: 600px; // TODO confirm fko

  display: grid;
  grid-template-columns: 24px 1fr 24px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 600px 1fr;
  }
  grid-template-rows: 80px 1fr auto;
  grid-template-areas:
    '. . .'
    '. modal .'
    '. . .';

  .content {
    grid-area: modal;
    z-index: 199999;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 101vh;
  width: 101vw;
  @include animation.fadeIn;

  // Only cover (mobile) app area so that it looks better when app is viewed on desktop
  // max-width: 600px; // TODO confirm fko
  margin: 0 auto;

  // TODO STYLES how to use variable here
  background-color: rgba(#0d1367, 0.85);
  // backdrop-filter: blur(3px);
}
