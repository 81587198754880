@use 'scss/text';

.container {
  margin-top: 8px;
}

.sessionsList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 128px;
}

.prefsContainer {
  margin: 8px 0 24px;

  .flexColumns {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      p {
        @include text.normal-grey(13px);

        span {
          font-weight: 600;
        }
      }
    }

    .prefsEdit {
      flex: 0;
    }
  }
}

//////////////////

.preferencesModalContainer {
  h2 {
    @include text.font-helvetica-light(12px);
    color: var(--toca-theme-grey7);
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .preferencesPane {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--toca-theme-white);
    border: 1px solid var(--toca-theme-grey-faded2);
    border-radius: 5.5px;
    padding: 16px;
    p {
      @include text.font-helvetica(14px);
      color: var(--toca-theme-black);
      margin-right: 8px;
    }

    .selectButton {
      width: 100%;
      display: grid;
      place-content: center;
      margin-bottom: -12px;
    }

    .dayTimeBold {
      font-weight: 600;
      margin-top: -16px;
    }

    .dayTimeRow {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  .repeatSelection {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    select {
      // TODO TEMP styles
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      outline: none;
      // width: 100%;
      height: 36px;
      padding: 0 36px 0 8px;
      margin: 0;
      border-radius: 5.5px;
      background: url('../../../assets/img/icons/new-chevron-down-black.svg') #fff no-repeat bottom 50% right 13px;
      @include text.font-helvetica(14px);
      color: var(--toca-theme-black);
      cursor: pointer;
      transition: all 0.1s;
    }
    .repeatText {
      @include text.font-helvetica-bold(14px);
      color: var(--toca-theme-black);
      margin-right: 8px;
    }
  }

  .smallText {
    @include text.font-helvetica(13px);
    color: var(--toca-theme-grey5);
    margin-bottom: 48px;
  }
}

// TODO STYLES fix svg
.arrowIcon {
  transform: rotate(90deg);
}
