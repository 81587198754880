@use 'scss/text';
@use 'scss/border';

.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p.info {
    font-size: 14px;
  }

  .bottomContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
