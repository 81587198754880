@mixin visually-hidden() {
  clip-path: inset(50%) !important;
  height: 0px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0px !important;
}

// TODO confirm usage matches revisedpagecontainer
@mixin exceed-global-padding {
  margin-left: -20px;
  margin-right: -20px;

  @media (min-width: 768px) {
    margin-left: -32px;
    margin-right: -32px;
  }
  @media (min-width: 1024px) {
    margin-left: -48px;
    margin-right: -48px;
  }
}

@mixin exceed-global-padding-classic {
  margin-left: -32px;
  margin-right: -32px;
}
