.checkboxInput {
  height: 0px;
  width: 0px;
  opacity: 0;
  &:checked {
    + .checkboxDisplay {
      background-color: var(--primary);
      border: solid 1px var(--primary);
    }
  }
}

.checkboxDisplay {
  display: inline-block;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border: solid 1px var(--primary);
  margin-top: 4px;
  margin-right: 20px;
  transition: all 0.1s;
  background: url('../../assets/img/icons/new-checked.svg') var(--toca--theme-white) center no-repeat;
  border-radius: 4px;
}

.checkboxLabel {
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: var(--toca-theme-black-faded2);

  a {
    // TODO STYLES font/style mixin
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--toca-theme-link-blue);
  }
}

// TODO temp alternate styling
.checkboxAltInput {
  height: 0px;
  width: 0px;
  opacity: 0;
  &:checked {
    + .checkboxAltDisplay {
      background-color: var(--theme-secondary);
      border: solid 1px var(--theme-secondary);
    }
  }
}

.checkboxAltDisplay {
  display: inline-block;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border: solid 1px var(--theme-secondary);
  transition: all 0.15s;
  background: url('../../assets/img/icons/new-checked.svg') var(--theme-white) center no-repeat;
  border-radius: 4px;
}

.checkboxAltLabel {
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: var(--theme-black);

  & .disabled {
    cursor: default;
  }

  a {
    // TODO STYLES font/style mixin
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--theme-blue);
  }
}
