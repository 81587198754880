@use 'scss/text';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;

  p {
    @include text.medium-grey(15px);
    text-transform: uppercase;
    line-height: 20px;

    span {
      margin-left: 0.5em;
      text-transform: none;
      @include text.bold-grey(15px);
      @include text.text-ellipsis;
    }
  }
}
