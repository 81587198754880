@use 'scss/text';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 100px;
}

.confirmationSubheading {
  @include text.medium-primary(16px); // TODO STYLES standardise
  margin-bottom: 12px;

  span {
    @include text.semibold-primary(16px);
  }
}
