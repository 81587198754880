@mixin scrollbars($foreground-color: rgba(255, 255, 255, 1), $background-color: rgba(255, 255, 255, 0.1)) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}

@mixin text-avenir-white-24 {
  color: #fff;
  font-family: 'Avenir';
  font-weight: bold;
  font-size: 24px;

  // @media (min-width: 1800px) {
  //   font-size: 40px;
  // }
}

@mixin text-poppin-white-14 {
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
}

@mixin lb-responsive-text-14 {
  @media (min-width: 768px) {
    font-size: 16px;
  }
  // @media (min-width: 1800px) {
  //   font-size: 30px;
  // }
}

@mixin list-grid {
  display: inline-grid;
  grid-template-columns: 48px 1fr 56px;

  @media (min-width: 768px) {
    grid-template-columns: 64px 1fr 72px;
  }

  // @media (min-width: 1800px) {
  //   grid-template-columns: 104px 1fr 72px 72px;
  // }
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
