.checkboxInput {
  // instead of 'display:none',
  // retain interactivity for screen-readers
  height: 0px;
  width: 0px;
  opacity: 0;
  &:checked {
    + .checkboxDisplay {
      background-color: var(--toca-website-checkbox-color);
      border: solid 1px var(--toca-website-checkbox-color);
    }
  }
}

.checkboxDisplay {
  display: inline-block;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border: solid 1px var(--toca-website-checkbox-color);
  margin-right: 20px;
  transition: all 0.1s;
  background: url('../../../assets/img/icons/new-checked.svg') #fff center no-repeat;
  border-radius: 4px;
}

.checkboxLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: var(--toca-theme-black-faded2);

  a {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--toca-theme-link-blue);
  }
}
