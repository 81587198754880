@import '../../scss/common';

.containerClassic {
  position: relative;
  min-height: 100dvh;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 32px 84px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 64px -24px var(--theme-primary);
  // @include zigzagBackground();

  &.uncapped-width {
    max-width: none;
    .page-container {
      background-image: none !important;
    }
  }
  &.full-width {
    max-width: 1200px;
    // .page-container {
    //   background-image: none !important;
    // }
  }
}

.containerRevised {
  position: relative;
  min-height: 100dvh;
  max-width: var(--sitewide-max-width);
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 64px -24px var(--theme-primary);

  &.zigzag-background {
    @include zigzagBackground();
  }

  @media (min-width: 768px) {
    padding: 0 32px;
  }

  @media (min-width: 1024px) {
    padding: 0 48px;
  }
}
