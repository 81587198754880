@use 'scss/text';

.container {
  min-height: 100dvh;
  width: 100%;
  max-width: 600px;
  margin-bottom: -84px; // override ClassicPageContainer bottom padding
  padding: 16px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--toca--theme-white);
}
.footerContainer {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.link {
  @include text.font-helvetica-light(13px);
  color: var(--toca-theme-grey2);
  flex: 1;
  text-decoration: underline;
  display: grid;
  place-content: center;
}
