@use 'scss/text';
@use 'scss/border';

.container {
  .maxWidthWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-width: 500px;
    margin: 0 auto;
  }

  .singleLevelWrapper {
    @include border.thick-grey;
    padding: 8px;
  }
}
