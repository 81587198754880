@use 'scss/text';
@use 'scss/border';

.profile {
  @include border.thick-grey;
  padding: 28px;
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  .avatar {
    height: 108px;
    width: 108px;
    border: 4px solid var(--theme-blue);
    border-radius: 50%;
    display: grid;
    place-content: center;

    svg {
      fill: var(--theme-blue);
      height: 80px;
      width: 80px;
    }
  }

  .divider {
    @include border.thin-grey;
    max-height: 2px;
    width: calc(100% + 60px);
  }

  .email {
    @include text.medium-grey(14px);
  }

  .name {
    @include text.bold-blue-light(18px);
    text-transform: uppercase;
  }

  .stat {
    @include text.medium-grey(13px);
    text-transform: uppercase;

    span {
      @include text.bold-grey(13px);
      text-transform: capitalize;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  row-gap: 24px;
}
