.admin {

    .analysis-result {
        margin-top: 20px;
        font-size: 12px;

        .section {
            border-bottom: 1px solid #4b536e;
            padding-bottom: 15px;
            margin-bottom: 10px;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .toggle-button {
        background: #3d4354;
        border-radius: 5px;
        color: #fff;
        padding: 3px;
        margin: 0 10px;
    }
}