.container {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  padding: 0 3px;
  margin: 22px 0;
}

.dividerLine {
  display: block;
  flex-grow: 1;
  border-top: 1px solid #ccc;
}

.dividerText {
  padding: 0 16px;
  color: var(--toca-theme-black-faded2);
  font-size: 16px;
  font-family: 'Avenir', 'Poppins';
  font-weight: 500;
}
