@use 'scss/text';
@use 'scss/shadow';
// TODO STYLES color variables

.container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding: 24px;
  padding-bottom: 112px;
}

.formHeading {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 16px;

  h1 {
    @include text.font-helvetica-heading(16px);
    // font-size: 12px;
    // font-family: 'Helvetica', sans-serif;
    // text-transform: uppercase;
    text-align: center;
    font-weight: 700; // TODO STYLES superbold preset?
    margin-bottom: 14px;
  }

  h2 {
    color: var(--toca-theme-grey2);
    text-align: center;
    font-size: 14px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 300;
    line-height: 30px;

    span {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-family: 'Helvetica', sans-serif;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
    }
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  justify-content: center;

  .buttonContainer {
    flex: 1;
    max-width: 600px;
    padding: 24px;
    border-radius: 12px 12px 0 0;
    border: 1px solid var(--toca-theme-grey6);
    background-color: var(--toca-theme-offwhite);

    .scrollText {
      @include text.font-helvetica-light(12px);
      color: var(--toca-theme-grey7);
      position: absolute;
      top: 4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.carouselWrapper {
  margin-bottom: 20px;
  margin-left: -24px;
  width: calc(100% + 48px);
  position: sticky;
  top: -24px;
  background: #fff;
  padding-top: 24px;
  margin-top: -24px;
  padding-bottom: 16px;
  @include shadow.dispersed-black-bottom;
}

.carouselSlide {
  width: 80px;
}

.carouselItem {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  max-width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.selected {
    background: var(--toca-website-fko-carousel);
    border-color: var(--toca-website-fko-carousel);
    color: #fff;
    .dateMonth {
      color: #fff;
    }
  }

  &.empty {
    opacity: 0.4;
  }
}

.dateDay {
  font-weight: 500;
  font-size: 20px;
}

.dateMonth {
  color: var(--toca-website-fko-carousel-grey);
  font-size: 14px;
  text-transform: uppercase;
}

.loader {
  font-size: 14px;
  padding: 20px;
  text-align: center;
}
