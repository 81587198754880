@use 'scss/element';

.container {
  @include element.exceed-global-padding-classic;
  min-height: 100vh;
  margin-bottom: -84px; // override ClassicPageContainer
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    border-radius: 12px 12px 0 0;
    padding: 0px 24px 36px;
    margin-bottom: -24px;

    &:nth-of-type(odd) {
      background-color: #fff;
      box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.15);
    }

    &:nth-of-type(even) {
      background-color: rgb(246, 246, 246);
    }

    &:first-of-type {
      padding-top: 12px;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 48px;
    }
  }
}

.subheading {
  color: var(--toca-theme-black-faded2);
  font-size: 14px;
}

.map {
  display: flex;
  width: 100%;
  height: min(calc(100vw - 48px), 600px);
  max-height: 552px;

  iframe {
    flex: 1;
    border: 1px solid #d3d3d3;
    border-radius: 5.5px;
    overflow: hidden;
  }
}

.location {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  h3 {
    color: #000;
    font-size: 22px;
    font-family: 'Helvetica';
    font-weight: bold;
    text-transform: capitalize;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 16px;
    line-height: 30px;
    font-family: 'Helvetica';
    color: var(--toca-theme-grey2);
  }
}

.topImage {
  @include element.exceed-global-padding-classic;
  width: calc(100% + 64px);
  height: 20vh;

  img {
    width: 100%;
    object-fit: contain;
    filter: brightness(75%);
  }
}

.imageWrapper {
  margin: 24px 0;
  height: min(calc(100vw - 48px), 400px);
  border-radius: 12px;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

.tokaWay {
  margin-bottom: 64px;
  h2 {
    color: #000;
    font-size: 22px;
    font-family: 'Helvetica';
    font-weight: bold;
    text-transform: uppercase;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica';
    //TODO Avenir
    color: var(--toca-theme-grey2);
  }
}

.exitLink {
  width: 100%;
}
