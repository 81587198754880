.container {
  width: 100%;
  height: 17px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 12px 0;
}

.sectionLine {
  display: block;
  flex-grow: 1;
  border-top: 1px solid #ccc;

  &.lightText {
    border-top: 1px solid #474d5d;
  }
}

.sectionText {
  padding-right: 16px;
  color: var(--toca-theme-black-faded2);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2.4px;

  &.lightText {
    color: var(--theme-text-grey);
  }

  @media (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.5ch;
  }
  @media (min-width: 1800px) {
    font-size: 24px;
  }
}
