@use 'scss/text';

.editOptionsModalContainer {
  & :not(:last-child) {
    margin-bottom: 24px;
  }

  .spacer {
    height: 24px;
  }
}

.spacer {
  height: 24px;
}

.ineligibleText {
  font-family: 'Poppins';
  font-size: 16px;
  color: var(--toca-theme-black-faded2);
  margin-bottom: 24px;
}

.ctaText {
  @include text.font-poppins(16px);
  color: var(--toca-theme-black);
  margin-bottom: 12px;
}

.buttonsMargin {
  margin: 36px 0 24px;
}

.errorText {
  @include text.font-poppins(16px);
  color: var(--toca-theme-black);
}

.errorSpacer {
  margin: 12px 0 36px;

  .helpLink {
    @include text.font-helvetica-bold(16px);
    color: var(--toca-theme-grey2);
    height: 48px;
    text-decoration: underline;
    display: grid;
    place-content: center;
  }
}

.buttonSpacer {
  margin-bottom: 12px;
}
