.container {
  display: none;

  &.sm {
    @media (max-width: 576px) {
      display: contents;
    }
  }

  &.md {
    @media (min-width: 577px) and (max-width: 768px) {
      display: contents;
    }
  }

  &.lg {
    @media (min-width: 769px) and (max-width: 992px) {
      display: contents;
    }
  }

  &.xl {
    // @media (min-width: 993px) and (max-width: 1200px) {
    @media (min-width: 993px) {
      display: contents;
    }
  }
}
