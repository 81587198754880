@use 'scss/text';
@use 'scss/border';
@use 'scss/shadow';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .logo {
    margin: 56px auto;

    svg {
      width: 240px;
      height: 48px;
    }
  }

  h1.heading {
    @include text.heading-primary(30px);
    line-height: 30px;
    margin-bottom: 24px;
    text-align: center;
    letter-spacing: 1.5px;
  }

  h2.subheading {
    @include text.medium-black(16px);
    line-height: 20px;
    text-align: center;
  }

  .socialAuth {
    display: flex;
    column-gap: 16px;

    .socialButton {
      @include border.thin-grey-dark;
      border-radius: 12px;
      flex: 1;
      display: grid;
      place-content: center;
      padding: 12px;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .flexContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .buttonWrapper {
    @include border.radius-standard;
    @include shadow.slight-black;

    .buttonText {
      @include text.medium-black(12px);
      letter-spacing: 2.4px;
    }
  }

  .forgotPasswordLinkContainer {
    display: flex;
    justify-content: center;

    a {
      color: var(--theme-text-grey);
      font-size: 13px;
    }
  }

  .helpLinkContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    a {
      color: var(--theme-text-grey);
      font-size: 13px;
      text-decoration: underline;
      padding: 12px;
    }
  }
}
