@use 'scss/text';

.addPlayerButton {
  margin: 36px auto 16px;
  border-radius: 2.5px;
  border: 1px solid var(--toca-theme-grey-faded2);
  display: flex;

  .buttonFlex {
    padding: 12px;
    position: relative;

    .plusIcon {
      position: absolute;
      left: 16px;
      top: calc(50% - 10px);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    span {
      margin: 0 36px;
      @include text.font-helvetica(12px);
      color: var(--toca-theme-black-faded2);
    }
  }
}
