.helpContainer {
  color: #000;
  padding: 24px;
  background-color: #fff;
  min-height: 100vh;

  &.freeTrialClasses {
    min-height: auto;
    max-width: 500px;
    margin: 36px auto 0;
    border: 1px solid var(--toca-website-primary-color);
    border-radius: var(--theme-border-radius-large);
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .helpInstructions {
    font-size: 13px;
    margin: 0 auto 30px;
    width: 90%;
  }
}

.hubspotForm {
  :global {
    form {
      margin: 0 auto;
      width: 90%;
    }

    .hs-form-field {
      margin-bottom: 15px;
    }

    label {
      font-size: 13px;
    }

    input[type='text'],
    input[type='tel'],
    input[type='number'],
    input[type='email'],
    textarea,
    select {
      width: 100%;
      height: 40px;
      border: none;
      outline: none;
      transition: all 0.3s;
      border-radius: 5px;
      background-color: var(--toca-theme-offwhite);
      padding: 7px;
      font-size: 13px;
      color: #000;
    }

    textarea {
      height: auto;
      min-height: 100px;
    }

    button,
    input[type='submit'] {
      width: 100%;
      height: 40px;
      border-radius: 12px;
      background-color: var(--toca-website-primary-color);
      border: 1px solid var(--toca-website-primary-color);
      color: var(--toca-website-primary-btn-text-color);
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      transition: all 1s;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
    }

    input[type='file'] {
      border: 1px solid #343f63;
      border-radius: 5px;
      padding: 7px;
      width: 100%;
    }

    .hs-error-msgs,
    .hs-error-msg {
      color: var(--toca-website-error-color);
      font-size: 13px;
    }

    .submitted-message {
      font-size: 14px;
      text-align: center;
    }
  }
}
