@use 'scss/text';

.container {
  cursor: pointer;
  border-radius: 2.5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  .textContainer {
    @include text.font-helvetica-superbold(14px); // TODO STYLES confirm 600 weight
    // font-weight: 600;
    line-height: 20px;
  }

  .icon {
    height: 12px;
    width: 12px;
    display: grid;
    place-content: center;
  }

  &.iconToLeft {
    flex-direction: row-reverse;
  }

  // available sizing [ 'standard', 'large' ]
  &.standard {
    padding: 4px 16px;

    &.iconToLeft .icon {
      margin-left: 0px;
      margin-right: 12px;
    }
    &:not(.iconToLeft) .icon {
      margin-left: 12px;
      margin-right: 0px;
    }
  }

  &.large {
    padding: 12px 48px;

    // icons on large buttons don't add margin/padding
    &.iconToLeft .icon {
      margin-left: -18px !important;
      margin-right: 18px !important;
    }
    &:not(.iconToLeft) .icon {
      margin-left: 18px !important;
      margin-right: -18px !important;
    }

    .textContainer {
      @include text.font-helvetica(12px);
      text-transform: uppercase;
    }
  }
}

// variants
.primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--toca--theme-white);
}
.light {
  background-color: var(--toca--theme-white);
  border: 1px solid var(--toca-theme-grey-faded2);
  color: var(--toca-theme-black-faded2);
}
.dark {
  background-color: none;
  border: 1px solid var(--toca-theme-grey-faded2);
  color: var(--toca-theme-black-faded2);
}
.ghost {
  background-color: none;
  border: 1px solid transparent;
  color: var(--toca-theme-black-faded2);
}
.warning {
  background-color: none;
  border: 1px solid var(--toca-theme-red-alternate);
  border: 1px solid var(--toca-theme-grey-faded2);
  color: var(--toca-theme-red-alternate);
  // .textContainer {
  //   font-weight: 600;
  // }
}
.danger {
  background-color: var(--toca-theme-red-alternate);
  border: 1px solid transparent;
  color: var(--toca--theme-white);
  .textContainer {
    text-transform: uppercase;
  }
}
// end variants
