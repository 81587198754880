.horizontalScroll {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  .item {
    display: contents;
    flex: 0 0 auto; /* Make items take up necessary space */
    scroll-snap-align: center;
    border: 1px solid #ccc;
    white-space: nowrap; /* Prevent items from wrapping */
  }
}
