@use 'scss/text';
@use 'scss/border';

.container {
  @include border.thick-grey;
  @include text.medium-black(14px);
  display: flex;
  padding: 8px 12px;

  &.alert {
    @include border.thick-alert;
    // @include text.medium-alert(14px);

    .alertIcon {
      display: grid;
      place-content: center;
      margin-right: 12px;

      svg {
        fill: var(--theme-alert);
        height: 24px;
        width: 24px;
      }
    }
  }
}
