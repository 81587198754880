@use 'scss/text';
@use 'scss/border';

.container {
  @include border.thick-grey;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  &.completed {
    background-color: var(--theme-grey-xlight);
  }

  .icon {
    height: 48px;
    width: 48px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;

    svg {
      height: 48px;
      width: 48px;
      g path:nth-of-type(2) {
        fill: var(--theme-black) !important;
      }
    }
  }

  .section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 48px;
    margin-right: 16px;
    min-width: 0;

    .name {
      @include text.medium-black(15px);
      @include text.ellipsis;
      text-align: left;
      text-transform: capitalize;
    }

    .date {
      @include text.medium-grey(13px);
      text-align: left;
    }
  }

  .rank {
    font-weight: 600;
    font-size: 16px;
  }
}
