.formInput {
  width: 100%;
  height: 48px;
  outline: none;
  transition: all 0.1s;
  border-radius: 12px;
  background-color: var(--toca-theme-offwhite);
  padding: 13px 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: var(--toca-theme-black-faded2);
  border: 1px solid transparent;

  &:focus {
    border: 1px solid var(--toca-website-primary-color);
  }

  &:disabled {
    border: 1px solid var(--toca-theme-black-faded);
    background-color: #fff;
  }

  &.errorState {
    border: 1px solid var(--toca-website-error-color);
    color: var(--toca-website-error-color);
  }
}
