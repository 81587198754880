@use 'components/Buttons/Form/FormButton.module';

.helpdesk {
  .help-instructions {
    font-size: 12px;
    margin: 0 auto 30px;
    width: 90%;

    a {
      color: var(--theme-blue);
      text-decoration: underline;
      margin: 0 3px;
    }

    ol {
      padding-left: 20px;

      li {
        margin-top: 10px;
      }
    }
  }

  #helpdesk-hubspot-form {
    form {
      margin: 0 auto;
      width: 90%;
    }

    .hs-form-field {
      margin-bottom: 15px;
    }

    label {
      font-size: 12px;
    }

    input[type='text'],
    input[type='tel'],
    input[type='number'],
    input[type='email'],
    textarea,
    select {
      border: 2px solid var(--theme-grey-light-alt);
      width: 100%;
      height: 40px;
      outline: none;
      transition: all 0.3s;
      border-radius: 5px;
      padding: 7px;
      font-size: 13px;
      color: #000;
    }

    textarea {
      height: auto;
      min-height: 100px;
    }

    button,
    input[type='submit'] {
      @extend .button;
      @extend .primary;
      margin-top: 15px;
    }

    input[type='file'] {
      border: 2px solid var(--theme-grey-light-alt);
      border-radius: 5px;
      padding: 7px;
      width: 100%;
    }

    .hs-error-msgs,
    .hs-error-msg {
      color: var(--toca-website-error-color);
      font-size: 13px;
    }

    .submitted-message {
      font-size: 14px;
      text-align: center;
    }
  }
}
