.container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  @media (min-width: 768px) {
    // padding: 24px 24px 0 24px;
    padding: 8px 8px 0 8px;
  }

  @media (min-width: 1024px) {
    padding: 8px 16px 0 16px;
  }
  // @media (min-width: 1800px) {
  //   // padding: 36px 136px 0 136px;
  //   padding: 20px 136px 0 136px;
  // }
}

.logoContainer {
  padding: 8px 0;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    max-width: 120px;
    object-fit: cover;
    // filter: invert(1);
  }
}

.heading {
  margin: 8px 0;
  display: flex;
  align-items: center;

  .headingText {
    flex: 1;
    display: flex;
    flex-direction: column;

    h2 {
      flex: 1;
      line-height: 36px;
      font-size: 24px;
      font-family: 'Poppins';
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }
    p {
      color: rgba(255, 255, 255, 0.75);
      font-size: 16px;
      font-family: 'Poppins';
      text-align: center;
      letter-spacing: 0.1ch;
      text-transform: capitalize;
    }
  }

  .blank,
  .icon,
  button {
    height: 36px;
    width: 36px;

    &.back {
      padding-top: 4px;
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 64px;

    .headingText h2 {
      line-height: 56px;
      font-family: 'Poppins';
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }
    .headingText p {
      font-size: 18px;
    }

    .blank,
    .icon,
    button {
      height: 56px;
      width: 56px;

      svg {
        height: 24px;
        width: 24px;
      }

      &.back {
        padding-top: 4px;
      }
    }
  }

  // @media (min-width: 1800px) {
  //   .headingText h2 {
  //     line-height: 112px;
  //     font-family: 'Poppins';
  //     font-size: 80px;
  //     font-weight: 500;
  //     text-align: center;
  //     text-transform: capitalize;
  //   }
  //   .headingText p {
  //     font-size: 36px;
  //   }

  //   .blank,
  //   .icon,
  //   button {
  //     height: 112px;
  //     width: 112px;

  //     svg {
  //       height: 42px;
  //       width: 42px;
  //     }

  //     &.back {
  //       padding-top: 4px;
  //     }
  //   }
  // }
}
