@use 'scss/text';

.container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 16px;
}

.headingText {
  @include text.font-helvetica-heading(24px);
  @include text.text-ellipsis;
  color: var(--toca-theme-white);
}

.backButton {
  margin-right: 16px;
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }
}
