@use 'scss/text';
@use 'scss/border';

.container {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.dividerLine {
  flex-grow: 1;
  @include border.thin-grey-bottom;
}

.dividerText {
  @include text.medium-grey(16px);
  line-height: 20px;
  padding: 0 16px;
}
