@use 'scss/text';
@use 'scss/border';

.loadingText {
  @include text.subheading-primary(14px);
  padding: 20px;
  text-align: center;
}

.noAppointmentsText {
  @include border.thick-grey;
  display: flex;
  flex-direction: column;
  padding: 24px;
  row-gap: 16px;

  p {
    @include text.medium-grey(16px);
    text-align: center;
  }
}

.sessionsList {
  margin-bottom: 100px;
}

.sessionWrapper {
  border: 2px solid transparent;
  border-radius: 5.5px;
  margin-bottom: 16px;
}

.bookSessionButton {
  // padding: 0 24px;
}

//
.bookingModal {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .repeatSection {
    @include border.thick-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    row-gap: 12px;

    .repeatText {
      @include text.subheading-primary(12px);
    }
  }

  .addendumText {
    @include text.normal-grey(12px);
    text-align: center;
    padding: 0 24px;
    margin-bottom: 12px;
  }
}
