@use 'scss/text';
@use 'scss/border';
@use 'scss/animation';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .logo {
    margin: 56px auto;

    svg {
      width: 240px;
      height: 48px;
    }
  }

  h1.heading {
    @include text.bold-grey(16px);
    line-height: 20px;
    margin-bottom: 24px;
    text-align: center;
  }

  .flexContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
