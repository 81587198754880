@use 'scss/border';
@use 'scss/text';
@use 'scss/animation';

.container {
  @include border.thick-primary;
  // position: fixed;
  // top: 80px;
  // left: 50%;
  // transform: translateX(-50%);
  // width: calc(100% - 48px);
  // max-width: 552px;
  padding: 16px;
  background-color: var(--theme-white);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: auto;
  max-height: 90vh;
  max-width: 90vw;

  @media screen and (max-width: 768px) {
    max-height: 80vh;
  }

  @include animation.fadeDrop(0.5s);

  .header {
    display: flex;
    position: relative;
    height: 20px;

    h2 {
      @include text.medium-grey(12px);
      @include text.ellipsis;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center;
      flex: 1;
      padding: 0 20px;
    }

    .closeButton {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;

      svg {
        height: 20px;
        width: 20px;
        fill: var(--theme-grey-dark);
      }
    }
  }
}
