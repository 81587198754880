.rdp {
  --rdp-accent-color: theme('colors.secondary');
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-color: theme('colors.white');
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: 2%;
  .rdp-day_range_middle {
    opacity: 0.5;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: var(--rdp-accent-color) !important;
  }

  .rdp-caption_label,
  .rdp-nav_icon {
    color: theme('colors.primary');
    font-size: 2rem;
  }

  .rdp-nav_icon {
    width: 12px;
  }

  .rdp-day_range_middle,
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: theme('colors.secondary');
    opacity: 0.3;
    color: theme('colors.white');
  }
}
