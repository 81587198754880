@use 'scss/text';
@use 'scss/border';

.container {
  overflow: scroll;

  .maxWidthWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-width: 500px;
    margin: 0 auto;
    overflow: scroll;
  }

  .benchmarkTablesWrapper {
    display: flex;
    column-gap: 16px;
    margin: 0 auto;
  }
}

.levelBenchmarkContainer {
  th {
    @include text.bold-black(10px);
    text-transform: uppercase;
  }
  td {
    @include text.semibold-grey(11px);
  }

  .benchmarkNum {
    background-color: var(--theme-grey-light-alt);
  }

  .levelHeading {
    @include text.heading-primary(18px);
    // letter-spacing: 0.5px; // TODO?
    @include border.table-grey-dark;
    border-bottom: none;
    text-align: center;
    padding: 4px 8px;

    // should these colors be the same as the heading colors in BaselineSessionData?

    // &.beginner {
    //   @include text.heading-black(18px);
    //   background-color: var(--theme-level-beginner);
    // }
    &.intermediate {
      // @include text.heading-black(18px);
      // background-color: var(--theme-level-intermediate);
      background-color: var(--theme-grey-light-alt);
    }
    // &.advanced {
    //   @include text.heading-white(18px);
    //   background-color: var(--theme-level-advanced);
    // }
    &.pro {
      // @include text.heading-white(18px);
      // background-color: var(--theme-level-pro);
      background-color: var(--theme-grey-light-alt);
    }
  }
}
