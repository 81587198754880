@use 'scss/text';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    @include text.bold-blue(20px);
    text-transform: uppercase;
  }

  .info {
    margin-top: 12px;

    span {
      @include text.bold-grey(14px);
    }

    p {
      @include text.normal-grey(14px);
      line-height: 20px;
    }
  }
}
