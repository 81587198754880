@use 'scss/border';

.autocompleteSearch {
  position: relative;
  .inputContainer {
    position: relative;
    input {
      width: 100%;
      height: 40px;
      border-radius: 30px;
      font-size: 14px;
      padding: 1rem;
      @include border.thin-grey;
      &:focus {
        outline: none;
      }
      padding-right: 90px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--theme-secondary-alt);
      color: white;
      padding: 12px 20px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      border-radius: 30px;
    }
  }
  .listContainer {
    position: absolute;
    top: 48px;
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
    z-index: 10;
    @include border.thin-grey;

    .listItem {
      border-radius: 4px;
      z-index: 100000;
      padding: 0.5rem;
      font-size: 12px;
    }

    .listItemSelected {
      background: var(--theme-primary);
      color: white;
    }
  }
}
