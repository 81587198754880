@use 'scss/text';
@import '../LeaderboardDetailsMixins.module.scss';

.container {
  @include list-grid;
  height: 40px;
  width: 100%;
  padding: 8px 0px;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    height: 52px;
    padding: 8px 12px;
  }

  // @media (min-width: 1800px) {
  //   height: 70px;
  //   padding: 16px 24px;
  // }

  p {
    font-size: 18px;
    font-weight: 500;
    &.name {
      margin-left: 48px;
    }

    &.score {
      text-align: right;
      padding-right: 4px;
    }

    // @media (min-width: 1800px) {
    //   font-size: 24px;
    //   &.name {
    //     margin-left: 72px;
    //   }
    // }
  }
}
