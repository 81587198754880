.container {
  position: fixed;
  bottom: 69px; // height of Navbar
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1400px;
  padding: 24px;
  background-color: var(--theme-white);
  box-shadow: 0px -4px 6px -4px var(--theme-grey);

  .wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
}
