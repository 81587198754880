@use 'scss/text';
@use 'scss/border';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  border-radius: 10px;
  transition: all 0.3s;

  &.default {
    @include border.thick-grey-light;
    @include text.heading-grey(18px);
  }

  &.primary {
    @include border.thick-blue;
    @include text.heading-white(18px);
    background-color: var(--theme-blue);
  }

  //TODO STYLE reflect global error and danger colors?
  &.error {
    border: solid 1px var(--toca-theme-grey4);
    background-color: var(--toca-theme-grey4) !important;
    cursor: not-allowed;
  }

  &.danger {
    border: 1px solid var(--toca-theme-red-alternate);
    background-color: var(--toca-theme-red-alternate);
    color: var(--toca-theme-white);
  }
}
