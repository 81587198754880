.socialContainer {
  width: 100%;
  display: flex;

  :not(:last-child) {
    margin-right: 8px;
  }

  button > span:not(.socialPrimary) {
    display: none;
  }
}

.socialButton {
  height: 46px;
  min-width: 46px;
  border-radius: 12px;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #fff;
  transition: all 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;

  &.socialPrimary {
    flex-grow: 1;
    background-color: var(--toca-website-primary-color);
    border: none;
    color: var(--toca-website-primary-btn-text-color);
  }
}

.headingSmall {
  font-size: 16px;
}

.restrictionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10001;
  display: grid;
  place-content: center;
}

.restrictionText {
  padding: 32px;
  margin: 0 auto;
  max-width: 90%;
  border-radius: 12px;
  background-color: var(--toca-theme-white);
  box-shadow: 0 0 16px -2px var(--toca-theme-black-faded2);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    margin: 12px 0;
    color: (--toca-website-primary-color);
  }
  h2 {
    font-weight: 300;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
  }
}
