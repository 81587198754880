@use 'scss/text';
@use 'scss/border';
@use 'scss/shadow';

.halfTransparentHalfWhite::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: transparent;
}
.halfTransparentHalfWhite::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: white;
}
